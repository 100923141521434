import './style.css'
import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import testVertexShader from './shaders/test/vertex.glsl'
import testFragmentShader from './shaders/test/fragment.glsl'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';


/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

/**
 * Test mesh
 */
// Geometry
const geometry = new THREE.PlaneGeometry(1, 1, 32, 32)

const vertexCount = geometry.attributes.position.count
const randoms = new Float32Array(vertexCount)

for (let i=0; i< vertexCount; i++) {
    randoms[i] = Math.random()
}
geometry.setAttribute('aRandom', new THREE.BufferAttribute(randoms, 1))

// Material
const flagTexture = textureLoader.load('/textures/starcraft.jpeg')
const material = new THREE.ShaderMaterial({
    vertexShader: testVertexShader,
    fragmentShader: testFragmentShader,
    wireframe: false,
    transparent: true,
    uniforms: {
        uFrequency: { value: new THREE.Vector2(10, 5) },
        uTime: { value: 0 },
        uColor: { value: new THREE.Color('orange') },
        uTexture: { value: flagTexture }
    }
})


// Mesh
const mesh = new THREE.Mesh(geometry, material)
mesh.scale.y *= 2/3
scene.add(mesh)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 0.5)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxAzimuthAngle = Math.PI/4;
controls.minAzimuthAngle = - Math.PI/4;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Effect Composer
 */
const composer = new EffectComposer(renderer);

// Debug
// const gui = new dat.GUI()
// gui.add(material.uniforms.uFrequency.value, 'x').min(0).max(20).step(0.01).name('frequencyX')
// gui.add(material.uniforms.uFrequency.value, 'y').min(0).max(20).step(0.01).name('frequencyY')

/**
 * Animate
 */
const clock = new THREE.Clock()

/**
 * Post-processing
 */
const renderPass = new RenderPass( scene, camera );
composer.addPass(renderPass);

const bloomPass = new UnrealBloomPass();
composer.addPass( bloomPass );

// const glitchPass = new GlitchPass();
// composer.addPass( glitchPass );

const fxaaPass = new ShaderPass( FXAAShader );
composer.addPass( fxaaPass );

// const dotScreenPass = new DotScreenPass( new THREE.Vector2( 0, 0 ), 0.5, 0.8 );
// composer.addPass( dotScreenPass );

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // update shader params
    material.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    composer.render(scene, camera)
    // renderer.render(scene, camera);




    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
